import Q from 'q';
import _ from 'underscore';
import redirect_to_user_front_home from '@/lib/common-service/redirect_to_user_front_home';

export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    const __this = this;
    redirect_to_user_front_home({
      clear_cache_get_user_info: true,
    });
  },
}
